<template>

  <v-container>
    <row-header :msg="msgRowHeader" :action="msgRowHeader.actionName"></row-header>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-row>
              <v-col>
                <v-autocomplete
                    dense
                    v-model="category"
                    label="Category"
                    :items="cat_prod"
                    item-text="name"
                    item-value="code"
                    clearable
                    return-object
                    @change="selByCat()"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
              item-key="name"
              class="elevation-1"
              loading
              loading-text="Loading... Please wait"
              v-show="loading"
          ></v-data-table>
          <v-data-table
              dense
              :headers="headers"
              :items="products"
              :footer-props="rowsPerPageItems"
              :items-per-page="pagination"
              item-key="no"
              :search="search"
              v-show="!loading"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon
                small
                class="mr-2 green--text"
                @click="editItem(item)"
                v-if="isAdmin"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                small
                class="red--text"
                @click="deleteItem(item)"
                v-if="isAdmin"
              >
                mdi-delete
              </v-icon>
              <span v-if="!isAdmin">-</span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <dialog-confirm></dialog-confirm>
  </v-container>

</template>

<script>
import RowHeader from "../../../components/content/RowHeader";
import DialogConfirm from "../../../components/content/DialogConfirm";
import { GetRequest, DeleteRequest } from '../../../store/services/users';

export default {
  components: {
    RowHeader,
    DialogConfirm,
  },
  data: () => ({
    search: null,
    rowsPerPageItems: {
      'items-per-page-options': [10, 20, 50, 100, 500, 1000]
    },
    pagination: 20,
    loading: true,
    msgRowHeader: {newUser: 'Create New', newMsg: 'Products List', actionName: 'ProductNew'},
    products: [],
    category: '',
    headers: [
      { text: 'No', align: 'end', value: 'no'},
      { text: 'Code', value: 'code' },
      { text: 'Category', value: 'category', width: '150px' },
      { text: 'Name', value: 'name', width: '200px' },
      { text: 'Price', value: 'price_str', align: 'end', width: '150px' },
      { text: 'Selling Price', value: 'up_selling_price', align: 'end', width: '150px' },
      { text: 'Provider Code', value: 'provider_prod_code', width: '130px' },
      { text: 'Status', value: 'active', width: '120px' },
      // { text: 'Created By', value: 'created_by' },
      // { text: 'Created At', value: 'created_at' },
      { text: 'Action', value: 'actions', sortable: false, },
    ],
    dataSelected: {},
    isAdmin: false,
    merchant_products: [],
    cat_prod: [],
  }),
  async mounted() {
    console.log('view page admin products');
    if (this.$store.getters.isLoggedIn) {
      if (this.$store.getters.currentUser.role.level === 1) {
        this.isAdmin = true;
      } else {
        this.msgRowHeader = {newUser: '', newMsg: 'Products List', actionName: 'non'};
      }
      await this.getMerProdData();
      await this.getProdData();
      this.cat_prod = await this.getCatData();
      await this.action.$on('delete-data-product', () => {
        this.deleteExecute();
      })
    }
  },
  beforeDestroy() {
    // this.action.$off('delete-data-user');
    // this.action.$off('open-dialog-conf-user');
  },
  methods : {
    async getMerProdData() {
      this.loading = await this.$store.dispatch('req_data')
      try{
        let response = await GetRequest('merchant-products');
        this.merchant_products = response.data;
        this.loading = await this.$store.dispatch('req_success');
        return true;
      }catch(err){
        this.loading = await this.$store.dispatch('req_failed');
        alert('Create user data failed ' + err.message);
        return false;
      }
    },
    async getProdData(cat = null) {
      let url = 'products';
      if (cat) {
        url = url + '?category=' + cat;
      }
      await this.$store.dispatch('req_data');
      this.loading = this.$store.getters.isLoading;
      try{
        let response = await GetRequest(url);
        this.products = response.data;
        this.products.forEach((val, i) => {
          val.no = i+1;
          val.active = this.mappingStatus(val.active);
          val.price_str = this.$options.filters.amountFormatNoPrefix(val.price);
          val.category_code = val.category.code;
          val.category = val.category.name;
          val.provider_prod_code = val.provider_prod_code.name;
          val.created_by = val.created_by.full_name;
          val.created_at = val.created_at ? this.$moment(val.created_at).format('YYYY-MM-DD HH:mm:ss') : '-';
          val.up_selling_price = val.price + Number(process.env.VUE_APP_FEE_KILAT_PULSA);

          if (!this.isAdmin) {
            let found = false;
            val.price_str = this.$options.filters.amountFormatNoPrefix(val.up_selling_price);
            this.merchant_products.forEach(val2 => {
              if (val2.category.code === val.category_code && !found) {
                val.up_selling_price = this.$options.filters.amountFormatNoPrefix(val.up_selling_price + val2.up_selling_price);
                found = true;
              }
            });
            if (!found) {
              val.up_selling_price = val.price_str;
            }
          } else {
            val.up_selling_price = this.$options.filters.amountFormatNoPrefix(val.up_selling_price);
          }
        })
        await this.$store.dispatch('req_success');
        this.loading = this.$store.getters.isLoading;
        return true;
      }catch(err){
        await this.$store.dispatch('req_failed');
        this.loading = this.$store.getters.isLoading;
        alert('Create user data failed ' + err.message);
        return false;
      }
    },
    async getCatData() {
      await this.$store.dispatch('req_data');
      this.overlay = await this.$store.getters.isLoading;
      try{
        let response = await GetRequest('categories');
        await this.$store.dispatch('req_success');
        this.overlay = await this.$store.getters.isLoading;
        return response.data;
      }catch(err){
        await this.$store.dispatch('req_failed');
        this.overlay = await this.$store.getters.isLoading;
        alert('failed to get data : ' + err.message);
        return [];
      }
    },
    editItem (item) {
      this.dataSelected = item;
      this.$router.push({name: 'ProductEdit', params: item})
    },
    deleteItem (item) {
      this.dataSelected = item;
      this.action.$emit('open-dialog-conf-product', true);
    },
    async deleteExecute () {
      // test dah
      await this.$store.dispatch('req_data')
      this.loading = this.$store.getters.isLoading;
      try {
        let response = await DeleteRequest('products/' + this.dataSelected._id);
        await this.$store.dispatch('req_success');
        this.loading = this.$store.getters.isLoading;
        // console.log(response);
        if (response.data && response.message === 'deleted') {
          alert('delete data success');
          await this.getProdData();
          return;
        }
        alert('delete data failed');
      } catch (e) {
        await this.$store.dispatch('req_failed');
        this.loading = this.$store.getters.isLoading;
        alert('Delete data failed ' + e.message);
      }
    },
    mappingStatus(status) {
      let res;
      if (status === 0) {
        res = 'Not Active';
      } else if (status === 1) {
        res = 'Active';
      } else if (status === 2) {
        res = 'Trouble';
      }

      return res;
    },
    selByCat(){
      if (this.category) {
        this.getProdData(this.category.code);
      } else {
        this.getProdData();
      }
    }
  }
}
</script>

<style scoped>

</style>
